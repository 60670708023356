import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Меню | піцерія Bella
			</title>
			<meta name={"description"} content={"Свято на будь-який смак!"} />
			<meta property={"og:title"} content={"Меню | піцерія Bella"} />
			<meta property={"og:description"} content={"Свято на будь-який смак!"} />
			<meta property={"og:image"} content={"https://povixolar.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://povixolar.com/img/1404945.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://povixolar.com/img/1404945.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://povixolar.com/img/1404945.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://povixolar.com/img/1404945.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://povixolar.com/img/1404945.png"} />
			<meta name={"msapplication-TileImage"} content={"https://povixolar.com/img/1404945.png"} />
			<meta name={"msapplication-TileColor"} content={"https://povixolar.com/img/1404945.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="30px 0 30px 0" md-padding="40px 0 40px 0" quarkly-title="About-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="grid"
				grid-gap="32px"
				sm-grid-gap="16px"
				grid-template-columns="4fr 5fr"
				align-items="center"
				width="50%"
				lg-width="100%"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Image
					src="https://povixolar.com/img/3.jpg"
					object-fit="cover"
					width="100%"
					height="80%"
					lg-height="100%"
				/>
				<Image
					src="https://povixolar.com/img/4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					max-height="460px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				padding="42px 42px 42px 42px"
				lg-width="100%"
				md-padding="24px 24px 24px 24px"
				md-margin="24px 0px 0px 0px"
				sm-align-items="flex-start"
				sm-padding="24px 24px 24px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--base"
					lg-text-align="center"
					text-transform="uppercase"
					letter-spacing="2px"
					sm-text-align="left"
				>
					Наш кулінарний рай
				</Text>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					color="--dark"
					font="normal 900 69px/1.2 --fontFamily-sans"
					lg-text-align="center"
					sm-text-align="left"
				>
					Меню
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="center"
					sm-text-align="left"
				>
					У піцерії Bella ми пишаємося тим, що пропонуємо різноманітне меню, яке демонструє найкращі страви італійської кухні. Кожна страва ретельно готується з найсвіжіших інгредієнтів та з пристрастю до досконалості. Незалежно від того, чи прийшли ви за класичною піцою, чи за вишуканими делікатесами, в нашому меню знайдеться щось, що задовольнить будь-яке бажання. Вивчіть наші пропозиції та відчуйте смаки, які роблять піцерію Bella Pizzeria улюбленим місцем для обіду.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-12">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="100%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					position="relative"
					padding="0px 0px 70% 0px"
				>
					<Image
						width="100%"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						src="https://povixolar.com/img/5.jpg"
						object-fit="cover"
						right={0}
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="100%"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Наші фірмові піци
				</Text>
			</Box>
			<Box
				flex-direction="column"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				width="33.3333%"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--grey"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Маргарита: Класична улюблена страва з нашим домашнім томатним соусом, свіжою моцарелою та базиліком.
					<br />
   Моцарела з буйволом: Покращуємо "Маргариту" вершковою моцарелою з буйволиного молока.
					<br />
   Помідори черрі: Додає свіжості завдяки солодким помідорам черрі.
					<br />
Пепероні: Вічний вибір з пікантними шматочками пепероні, моцарелою та нашим насиченим томатним соусом.
					<br />
   Подвійна пепероні: Для тих, хто прагне ще більше гострого, пікантного смаку.
					<br />
   Пряний медовий дризл: Унікальна родзинка з пряною медовою глазур'ю.
				</Text>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				width="33.3333%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--grey"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					
Грибна з трюфелями: Розкішне поєднання трюфельної олії, лісових грибів та моцарели.
					<br />
   Часникова масляна скоринка: Додає скоринці маслянистої, часникової скоринки.
					<br />
   Посипана пармезаном: посипана свіжо натертим пармезаном для додаткового вибуху смаку.
					<br />
Середземноморський овочевий: барвисте поєднання смажених овочів, сиру фета та нотки орегано.
					<br />
   Оливкове асорті: Різноманітні оливки та маслини для солоної заправки.
					<br />
   В'ялені помідори: Додаємо нотку солодкості за допомогою в'ялених томатів.

				</Text>
			</Box>
			<Box
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				display="flex"
				width="33.3333%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 60% 0px"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					width="100%"
					height="auto"
					overflow-y="hidden"
				>
					<Image
						bottom="0px"
						min-height="100%"
						src="https://povixolar.com/img/6.jpg"
						display="block"
						width="100%"
						left={0}
						right={0}
						object-fit="cover"
						position="absolute"
						top="auto"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" color="--dark" quarkly-title="Reviews-14">
			<Override slot="SectionContent" md-padding="0px 0 0px 0" grid-template-columns="repeat(3, 1fr)" grid-template-rows="auto" />
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box display="flex" align-items="center" md-min-width={0} md-min-height={0}>
						<Box min-width="10px" min-height="10px">
							<Text margin="0px 0px 17px 0px" font="normal 600 20px/1.5 --fontFamily-sans" color="--dark">
							Ситні варіанти
							</Text>
						</Box>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="--dark">
						М'ясна: Насичені пепероні, ковбасою, шинкою та беконом, приправлені моцарелою.
							<br />
   "Екстра сир": Для любителів сиру, які бажають отримати додатковий шар липкої смакоти.
							<br />
   BBQ Drizzle: Прикрашений димчастим соусом барбекю.
							<br />
Курка барбекю: Ніжна куряча грудка, червона цибуля, кінза та наш пікантний соус BBQ.
							<br />
   Шматочки ананаса: Солодкий контраст із соковитими шматочками ананаса.
							<br />
   Копчена Гауда: З насиченим, димчастим смаком сиру Гауда.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box display="flex" align-items="center" md-min-width={0} md-min-height={0}>
						<Box min-width="10px" min-height="10px">
							<Text margin="0px 0px 17px 0px" font="normal 600 20px/1.5 --fontFamily-sans" color="--dark">
							Свіжі салати
							</Text>
						</Box>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="--dark">
						Доповніть піцу одним із наших свіжих, хрустких салатів.
							<br />
Салат "Цезар": Салат ромен, сухарики, сир пармезан та заправка "Цезар".
							<br />
   Курка-гриль: Додавання білка з соковитою куркою-гриль.
							<br />
   Філе анчоусів: Для солонуватого, умамі підсилення.
							<br />
Грецький салат: Мікс зелені, оливки каламата, сир фета, огірки та пікантний вінегрет.
							<br />
   Екстра фета: Ще більше вершкового, пікантного сиру фета.
							<br />
   Скибочки червоної цибулі: Додають гострого, ароматного хрускоту.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box display="flex" align-items="center" md-min-width={0} md-min-height={0}>
						<Box min-width="10px" min-height="10px">
							<Text margin="0px 0px 17px 0px" font="normal 600 20px/1.5 --fontFamily-sans" color="--dark">
							Декадентські десерти
							</Text>
						</Box>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="--dark">
						Тірамісу: Класичний італійський десерт з шарами просочених кавою "дамських пальчиків" і сиру маскарпоне.
							<br />
   Шоколадна стружка: Посипаний насиченою шоколадною стружкою.
							<br />
   Еспресо Шот: Подається з порцією міцного еспресо для додаткової насолоди.
							<br />
Морозиво Тріо: Тріо смаків ремісничого морозива: ваніль, шоколад і фісташка.
							<br />
   Свіжі ягоди: Прикрашається міксом свіжих сезонних ягід.
							<br />
   Листя м'яти: Освіжаючий штрих зі свіжим листям м'яти.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Зв'язатися з нами
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Це лише короткий огляд того, що може запропонувати піцерія "Белла". Якщо у вас є якісь дієтичні проблеми або особливі побажання, будь ласка, звертайтеся до нас. Наш персонал завжди готовий допомогти вам зробити ваш обід винятковим.
<br/><br/>
Завітайте до піцерії "Белла" вже сьогодні та пориньте у неперевершену кулінарну мандрівку. Скуштуйте традиції, насолодіться різноманітністю та відчуйте пристрасть у кожному шматочку!

				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--dark"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Контакти
				</Link>
			</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://povixolar.com/img/7.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
				/>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});